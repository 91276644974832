'use client';

import Image from 'next/image';
import Navigation from '@/src/components/elements/Navigation';
import bg from '@/public/bg.jpg';
import dynamic from 'next/dynamic';
import LinkComponent from '@/src/components/ui/LinkComponent';

const Counter = dynamic(() => import('@/src/components/elements/Counter'), {
    ssr: false,
});

const Header = () => {
    return (
        <header className="mt-20 overflow-hidden md:mt-0 md:-mx-15">
            <div className="relative rounded-xl overflow-hidden md:scale-105">
                <LinkComponent
                    href="/"
                    className="absolute w-2/10 h-3/5 left-3/100 top-2/10 opacity-0"
                >
                    Black Friday
                </LinkComponent>
                <div className="w-full relative"></div>
                <Image src={bg} alt="Picture" priority />
                <Counter customClasses="md:scale-115 md:origin-center sm:scale-110" />
            </div>

            <div className="py-20 relative flex justify-between lg:py-10 md:px-15">
                <Navigation />
            </div>
        </header>
    );
};

export default Header;
