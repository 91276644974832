'use client';

import LinkComponent from '@/src/components/ui/LinkComponent';
import { IPopulars } from '@/types/name';
import { useCallback, useState } from 'react';
import Container from '@/src/components/ui/Container';
import clsx from 'clsx';

const typeMapping: Record<keyof IPopulars, string> = {
    brands: 'brand',
    categories: 'tag',
    shops: 'shop',
    series: 'series',
};

const PopularTags = ({ populars }: { populars: IPopulars }) => {
    const [selected, setSelected] = useState<keyof IPopulars>('brands');

    const handleClick = useCallback((type: keyof IPopulars) => {
        setSelected(type);
    }, []);

    return (
        <>
            <Container>
                <div className="flex gap-15 md:overflow-x-auto md:no-scrollbar">
                    {Object.entries(populars).map(([type, { title }], i) => (
                        <div
                            key={`${title}_${i}`}
                            className="flex gap-4 uppercase"
                        >
                            <button
                                onClick={() =>
                                    handleClick(type as keyof IPopulars)
                                }
                                className={clsx(
                                    'pb-8 pt-16 text-center capitalize whitespace-nowrap md:text-sm',
                                    selected === type
                                        ? 'text-white border-b-4 border-primary'
                                        : 'text-lightGray',
                                )}
                            >
                                {title}
                            </button>
                        </div>
                    ))}
                </div>
            </Container>
            <div className="bg-footerBg/5 py-36">
                <Container>
                    <div className="grid grid-flow-row grid-cols-5 auto-cols-min items-center gap-20 md:gap-15 md:grid-cols-4 ms:grid-cols-3 sm:grid-cols-2 ms:gap-10">
                        {Object.keys(populars).map(type =>
                            populars[type as keyof IPopulars].items.map(
                                ({ id, title, slug }) =>
                                    selected === type && (
                                        <LinkComponent
                                            key={id}
                                            className="break-word hover:text-primary md:text-sm"
                                            href={`/${
                                                typeMapping[
                                                    type as keyof IPopulars
                                                ]
                                            }/${slug}`}
                                        >
                                            {title}
                                        </LinkComponent>
                                    ),
                            ),
                        )}
                    </div>
                </Container>
            </div>
        </>
    );
};

export default PopularTags;
